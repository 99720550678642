<script>

import {httpRequest} from "@/api";
import {shortenDigits} from "@/utils/utils";
import ProgressBar from "@/components/UI/ProgressBar/index.vue";
import {formatDate} from "@/utils/utils";

export default {
    name: 'RankingTotals',
    components: {ProgressBar},
    props: {
        app: {
            type: Object,
            required: true
        },
        currentCountry: {
            type: Object,
            required: true
        },
        currentCountryManual: {
            type: Object,
            required: true
        },
        dateFilter: {
            type: Object,
        },
        dateFilterMode: {
            type: String,
        },
    },
    watch: {
        app(newVal, oldVal) {
            if (+newVal?.id !== +oldVal?.id) {
                this.fetchRankingTotals();
            }
        },
        currentCountryManual(newVal, oldVal) {
            if (newVal?.code !== oldVal?.code) {
                this.fetchRankingTotals(newVal?.code);
            }
        },
        dateFilter(newVal, oldVal) {
            if (newVal?.start !== oldVal?.start || newVal?.end !== oldVal?.end) {
                this.fetchRankingTotals();
            }
        },
    },
    data() {
        return {
            rankingTotalData: null,
            rankingTotalLoaded: false,
        }
    },
    mounted() {
        this.fetchRankingTotals();
    },
    methods: {
        shortenDigits(val) {
            return shortenDigits(val);
        },
        async fetchRankingTotals(countryCode) {
            let currentCountryCode = countryCode ?? this.currentCountry?.code;

            if (!this.app?.id || !currentCountryCode || !this.dateFilter.start || !this.dateFilter.end) {
                return;
            }

            let urlQuery = '?app_id=' + this.app.id +
                '&country_code=' + currentCountryCode +
                '&date_from=' + formatDate(this.dateFilter?.start, 'digits-dash') +
                '&date_to=' + formatDate(this.dateFilter?.end, 'digits-dash') +
                '&mode=' + this.dateFilterMode;

            this.rankingTotalLoaded = false;
            this.rankingTotalData = null;

            let result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.rankingKeywords.RANKING_KEYWORDS_TOTALS + urlQuery);

            if (result && result?.total) {
                let tempObj = {
                    total: result?.total,
                    total_top_10: result?.total_top_10,
                    ranges: {
                        top_1_1: {...result?.ranges?.top_1_1},
                        top_2_5: {...result?.ranges?.top_2_5},
                        top_6_10: {...result?.ranges?.top_6_10},
                        top_11_20: {...result?.ranges?.top_11_20},
                        top_21_50: {...result?.ranges?.top_21_50},
                    }
                };
                this.rankingTotalData = {...tempObj};
            }

            this.rankingTotalLoaded = true;
        },
        constructDynamicCssClass(val) {
            if (+val === 0) {
                return;
            }
            return +val > 0 ? 'green' : 'red';
        },
        calculateProgressFilledPercent(total, currVal) {
            const ttl = +total;
            const val = +currVal;

            if (!ttl || !val) {
                return;
            }

            return 100 - ((val / ttl) * 100);
        },
    }
}
</script>

<template>
    <div class="rankings-totals-block">
        <div class="ranking-totals">
            <div class="total-item default-shadow">
                <div class="content">
                    <div class="totals-value"
                         :class="{skeleton: !rankingTotalLoaded}">
                        <template v-if="rankingTotalLoaded">{{ shortenDigits(rankingTotalData?.total) }}</template>
                        <template v-else></template>
                    </div>
                    <div class="totals-title">Total Keywords</div>
                </div>
            </div>
            <div class="total-item default-shadow">
                <div class="content">
                    <div class="totals-value"
                         :class="{skeleton: !rankingTotalLoaded}">
                        <template v-if="rankingTotalLoaded">{{ shortenDigits(rankingTotalData?.total_top_10) }}</template>
                        <template v-else></template>
                    </div>
                    <div class="totals-title">Total Keywords in Top 10</div>
                </div>
            </div>
        </div>
        <div class="ranking-tops default-shadow">
            <div class="ranking-top-item">
                <div class="top-item-heading">
                    <div class="title">Top 1</div>
                    <div class="value-block">
                        <div class="dynamics"
                             :class="constructDynamicCssClass(rankingTotalData?.ranges?.top_1_1?.dynamic)"
                             v-if="rankingTotalData?.ranges?.top_1_1?.dynamic">
                            {{ rankingTotalData?.ranges?.top_1_1?.dynamic }}
                        </div>
                        <div class="value">
                            {{ rankingTotalData?.ranges?.top_1_1?.value }}
                        </div>
                    </div>
                </div>
                <progress-bar
                        :width="(100 - calculateProgressFilledPercent(rankingTotalData?.total, rankingTotalData?.ranges?.top_1_1?.value)) + '%'"
                        height="8px"
                        bar-type="gray"/>
            </div>
            <div class="ranking-top-item">
                <div class="top-item-heading">
                    <div class="title">Top 11-20</div>
                    <div class="value-block">
                        <div class="dynamics"
                             :class="constructDynamicCssClass(rankingTotalData?.ranges?.top_11_20?.dynamic)"
                             v-if="rankingTotalData?.ranges?.top_11_20?.dynamic">
                            {{ rankingTotalData?.ranges?.top_11_20?.dynamic }}
                        </div>
                        <div class="value">
                            {{ rankingTotalData?.ranges?.top_11_20?.value }}
                        </div>
                    </div>
                </div>
                <progress-bar
                        :width="(100 - calculateProgressFilledPercent(rankingTotalData?.total, rankingTotalData?.ranges?.top_11_20?.value)) + '%'"
                        height="8px"
                        bar-type="gray"/>
            </div>
            <div class="ranking-top-item">
                <div class="top-item-heading">
                    <div class="title">Top 2-5</div>
                    <div class="value-block">
                        <div class="dynamics"
                             :class="constructDynamicCssClass(rankingTotalData?.ranges?.top_2_5?.dynamic)"
                             v-if="rankingTotalData?.ranges?.top_2_5?.dynamic">
                            {{ rankingTotalData?.ranges?.top_2_5?.dynamic }}
                        </div>
                        <div class="value">
                            {{ rankingTotalData?.ranges?.top_2_5?.value }}
                        </div>
                    </div>
                </div>
                <progress-bar
                        :width="(100 - calculateProgressFilledPercent(rankingTotalData?.total, rankingTotalData?.ranges?.top_2_5?.value)) + '%'"
                        height="8px"
                        bar-type="gray"/>
            </div>
            <div class="ranking-top-item">
                <div class="top-item-heading">
                    <div class="title">Top 21-50</div>
                    <div class="value-block">
                        <div class="dynamics"
                             :class="constructDynamicCssClass(rankingTotalData?.ranges?.top_21_50?.dynamic)"
                             v-if="rankingTotalData?.ranges?.top_21_50?.dynamic">
                            {{ rankingTotalData?.ranges?.top_21_50?.dynamic }}
                        </div>
                        <div class="value">
                            {{ rankingTotalData?.ranges?.top_21_50?.value }}
                        </div>
                    </div>
                </div>
                <progress-bar
                        :width="(100 - calculateProgressFilledPercent(rankingTotalData?.total, rankingTotalData?.ranges?.top_21_50?.value)) + '%'"
                        height="8px"
                        bar-type="gray"/>
            </div>
            <div class="ranking-top-item">
                <div class="top-item-heading">
                    <div class="title">Top 6-10</div>
                    <div class="value-block">
                        <div class="dynamics"
                             :class="constructDynamicCssClass(rankingTotalData?.ranges?.top_6_10?.dynamic)"
                             v-if="rankingTotalData?.ranges?.top_6_10?.dynamic">
                            {{ rankingTotalData?.ranges?.top_6_10?.dynamic }}
                        </div>
                        <div class="value">
                            {{ rankingTotalData?.ranges?.top_6_10?.value }}
                        </div>
                    </div>
                </div>
                <progress-bar
                        :width="(100 - calculateProgressFilledPercent(rankingTotalData?.total, rankingTotalData?.ranges?.top_6_10?.value)) + '%'"
                        height="8px"
                        bar-type="gray"/>
            </div>
        </div>
    </div>
</template>