<template>
  <keywords-header-tabs active="ranking"/>

  <div class="ranking-keywords" :key="currentApp.id">

    <div class="ranking-table-wrap">
      <div class="new-container">
        <div class="search-keywords-block" ref="searchKeywordsBlock">

          <div class="app_select_block">
            <search-app-input @search-input-changed="searchInputChanged"
                              @outside="searchInputReset"
                              height="36px"
                              :show-current="false"
                              results-max-height="256px"
                              :selected-app="selectedApp"
                              :app-search-loaded="appSearchLoaded"
                              :search-results="liveSearchApps">
              <template v-slot:results="results">
                <div v-for="result in results"
                     @click="searchResultsAppClicked(result)"
                     class="result-item">
                  <div class="product-logo">
                    <template v-if="result?.logo">
                      <app-image
                          :width="22"
                          :height="22"
                          :src="result?.logo"
                      />
                    </template>
                    <template v-else>
                      <div class="empty-logo"></div>
                    </template>
                  </div>

                  <div class="product-name">{{ result?.title ?? '' }}</div>
                  <div class="product-category"
                       :title="result?.developer_name ?? ''">{{ result?.developer_name ?? '' }}
                  </div>
                </div>
              </template>
            </search-app-input>
            <div class="select_competitors" @click="openCompetitorsModal">Or select from your competitors</div>
          </div>

          <div class="title_selector_block">
            <div class="selected_app_block">
              <span class="logo" v-if="selectedApp.info">
                <app-image
                    :width="36"
                    :height="36"
                    :src="selectedApp.info?.logo"
                />
              </span>
              <div class="selected_title" v-if="selectedApp.info">
                <a :href="selectedApp.info?.store_link" target="_blank">
                  <svg-icon v-once icon="link-icon" class="link-icon common-app-store-link"/>
                </a>
                <span>{{ selectedApp.info?.title }}</span>
              </div>
              <span class="store_logo" v-if="selectedApp.info">
                <img v-if="selectedApp.info?.store === 'APP_STORE'"
                     src="@/assets/images/icons/app_store.png"
                     alt="Store icon"
                     class="store-icon"
                     width="22"
                     height="22">
                <img v-else
                     src="@/assets/images/icons/google_play.png"
                     alt="Store icon"
                     class="store-icon"
                     width="22"
                     height="22">
              </span>
            </div>
            <div>
              <date-picker-with-range
                  :to-right="true"
                  :date-filter="dateFilter"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :last-days-count="1"
                  :is-active-picker="true"
                  :key="datePickerRerenderKey"
                  @reset-button-clicked="resetDatePicker"
                  @date-filter-changed="applyDatePicker"/>
            </div>
          </div>
        </div>

        <ranking-totals
         :key="workingApp.id + '|' + currentApp.id + '|' + currentCountry.id + '|' + dateFilter.start + '|' + dateFilter.end"

         v-if="currentApp?.id && currentCountry?.code && dateFilter"
         :app="workingApp"
         :current-country="currentCountry"
         :current-country-manual="currentCountryManual"
         :date-filter="dateFilter"
         :date-filter-mode="mode"
        ></ranking-totals>

        <div class="ranking-keywords-page">
          <RankingKeywordsTable
              v-if="dateFilter.start && dateFilter.end && workingApp.id && currentCountry.id"

              ref="rankingKeywordsTable"

              @date-filter-changed="applyDatePicker"
              :key="workingApp.id + '|' + currentApp.id + '|' + currentCountry.id + '|' + dateFilter.start + '|' + dateFilter.end"
              :date-filter="dateFilter"
              :current-country="currentCountry"
              :working-app="workingApp"

              @tracking-keywords-changed="onTrackingChanged"
          ></RankingKeywordsTable>
        </div>
      </div>
    </div>
  </div>

  <competitors_select_modal :current-app="currentApp"
                            ref="competitors_select_modal"
                            :init-select-emit="false"
                            :one-select-mode="true"
                            :current-country="currentCountry"
                            :key="currentApp.id + '|' + currentCountry.id"
                            @save-and-continue="handleSelectCompetitors"
  />
</template>

<script>
import {mapGetters} from "vuex";
import TheHeader from '@/components/TheHeader/index';
import {rankingKeywordsTableConfig} from "@/configs/page-tables-configs/ranking-keywords";
import {rankingKeywordsGPTableConfig} from "@/configs/page-tables-configs/ranking-keywords-gp";
import FiltersDropdown from "@/components/Dropdowns/FiltersDropdown/index";
import SearchAppInput from '@/components/UI/SearchAppInput/index';
import ProgressIndicator from "@/components/UI/ProgressIndicator/index";
import {decomposeUrlToObject, formatDate, getDefaultMinDate, reverseDayMonthDate} from "@/utils/utils";
import DatepickerWithRange from "@/components/UI/DatepickerWithRange";
import RankingTotals from "./RankingTotals/index.vue";
import FiltersComponent from "@/components/UI/FiltersComponent/index.vue";
import TableWrapComponent from "@/views/SearchTerms/RankingKeywords/components/TableWrapComponent/index.vue";
import NoDataFoundBlock from "@/components/DataTables/NoDataFoundBlock/index.vue";
import BasicSearchInput from "@/components/UI/BasicSearchInput/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import Competitors_select_modal from "@/views/CompetitorsFinderPage/Competitors/competitors_select_modal.vue";
import RankingKeywordsTable from "./RankingKeywordsTable.vue";
import HeaderTabs from "../../../components/UI/HeaderTabs/index.vue";
import AppImage from "../../../components/UI/ImageLazyLoad/index.vue";
import SvgIcon from "../../../components/UI/SvgIcon/index.vue";
import myApps from "../../../api/modules/my-apps";
import {httpRequest} from "@/api";
import KeywordsHeaderTabs from "@/views/SearchTerms/components/KeywordsHeaderTabs/index.vue";

export default {
  name: 'RankingKeywords',
  components: {
    KeywordsHeaderTabs,
    SvgIcon,
    AppImage,
    HeaderTabs,
    RankingKeywordsTable,
    Competitors_select_modal,
    RankingTotals,
    'the-header': TheHeader,
    'search-app-input': SearchAppInput,
    'progress-indicator': ProgressIndicator,
    'filters-dropdown': FiltersDropdown,
    'date-picker-with-range': DatepickerWithRange,
    'basic-search-input': BasicSearchInput,
    'custom-dropdown': CustomDropdown,
    FiltersComponent,
    TableWrapComponent,
    NoDataFoundBlock,
    TranslateTool,
  },
  data() {
    return {
      topBlockScroll: 0,

      title: '',
      loaderText: '',
      headers: rankingKeywordsTableConfig.headers,
      searchAppInput: '',
      currentKeywordCountry: {},
      searchResultsLoading: false,
      competitorsModalIsOpen: false,
      liveSearchApps: [],
      selectedApp: {},
      appSearchLoaded: false,
      currentFilterTab: {
        value: 'all',
        title: 'All keywords'
      },
      dateFilter: {},
      minDate: new Date(),
      maxDate: new Date(),
      filtersCollapsedCounter: 0,
      initialUrlQueryParams: '',
      tabDropdownOpened: false,
      filtersData: [],
      sortData: {
        sort_by: 'popularity',
        order: 'desc'
      },
      appliedFiltersObject: {},
      initialActiveFiltersObj: {},
      resetFilters: 0,
      checkedArray: [],
      keywordsList: [],
      keywordsFullList: [],
      searchKeywordsInput: '',
      keywordsLoaded: false,
      tableIsLoading: true,
      filtersReset: true,
      currentCountryCode: null,
      showKeywordsLoader: false,
      translateIsActive: false,
      datePickerRerenderKey: 0,
      mode: 'two_days',
      defaultDateFilters: true,
      lastSearchI: 0,

      initTableMaxHeightFix: null,
    }
  },
  methods: {
    onWheel(event) {
      if (!this.initTableMaxHeightFix) {
        this.initTableMaxHeightFix = this.$refs.rankingKeywordsTable.tableMaxHeightFix;
      }

      if (!event.target.closest('.ranking-table-wrap')) {
        return;
      }

      const maxScroll = 381;

      const factor = event.deltaY > 0 ? 1 : -1;

      if (event.target.closest('.table-container')) {
        let tableScroll = event.target.closest('.table-container').querySelector('.common-scrollbar').scrollTop;

        if (factor < 0 && tableScroll > 0) {
          return;
        }
      }

      const step = 40;
      let tmp = this.topBlockScroll + step * factor;
      if (tmp < 0) {
        tmp = 0;
      } else if (tmp > maxScroll) {
        tmp = maxScroll;
      }
      this.topBlockScroll = tmp;
      this.$refs.searchKeywordsBlock.style.marginTop = '-' + this.topBlockScroll + 'px';

      let tmpTable = this.$refs.rankingKeywordsTable.tableMaxHeightFix + step * factor * -1;
      if (tmpTable > this.initTableMaxHeightFix) {
        tmpTable = this.initTableMaxHeightFix;
      } else if (tmpTable < this.initTableMaxHeightFix - maxScroll) {
        tmpTable = this.initTableMaxHeightFix - maxScroll;
      }
      this.$refs.rankingKeywordsTable.tableMaxHeightFix = tmpTable;
    },
    openCompetitorsModal() {
      this.$refs.competitors_select_modal.openModal();
    },
    handleSelectCompetitors(e) {
      this.searchResultsAppClicked({
        id: e.competitorId,
        title: e.title,
      });
    },
    async searchResultsAppClicked(item) {
      this.selectedApp = {...item};
    },
    async applyDatePicker(event) {
      if (this.isFreeUser) {
        this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
        return;
      }

      let tempFilterObj = {
        start: event?.start,
        end: event?.end,
      }
      this.dateFilter = {...tempFilterObj};

      this.minDate = getDefaultMinDate();
      this.showDatepicker = false;
    },
    async resetDatePicker(mode = 'default') {
      this.minDate = getDefaultMinDate();

      let tempObj = this.getLastDaysRange(1);

      this.dateFilter = {...tempObj};

      this.headers = this.currentApp?.store?.key === 'APP_STORE' ? [...rankingKeywordsTableConfig?.headers] : [...rankingKeywordsGPTableConfig?.headers];

      if (mode !== 'no-reload') {
        this.mode = 'one_day';
        this.defaultDateFilters = true;
        await this.updateSort();
      }
      this.defaultDateFilters = false;
    },
    getLastDaysRange(days) {
      const nowStart = new Date();
      const nowEnd = new Date();
      return {
        start: nowStart.setDate(nowStart.getDate() - days - 1),
        end: nowEnd.setDate(nowEnd.getDate() - 1),
      }
    },
    upgradeNowClicked() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
    formatDate(val) {
      return formatDate(val, 'digits');
    },
    setInitialQueryUrl(initialQueryString) {
      let queryString = initialQueryString;
      queryString = queryString.charAt(0).replace('?', '&') + queryString.slice(1);
      this.initialUrlQueryParams = queryString;
    },
    searchInputReset() {
      this.liveSearchApps = [];
      this.appSearchLoaded = false;
    },
    async searchInputChanged(string) {
      let searchI = ++this.lastSearchI;
      if (string?.length > 2) {
        this.appSearchLoaded = false;

        const url = process.env.VUE_APP_API_URL
            + this.$ApiUrls.liveSearch.KEYWORDS_TOP
            + '?country_code=' + this.currentCountryCode
            + '&device=iphone'
            + '&app_id=' + this.currentApp?.id
            + '&apps_only=true'
            + '&keyword=' + string;
        const result = await httpRequest('GET', url);

        if (searchI !== this.lastSearchI) {
          return;
        }

        let searchApps;
        if (result?.list?.isArray) {
          searchApps = [...result.list];
        } else {
          searchApps = Object.values(result.list);
        }

        this.liveSearchApps = searchApps?.map(resultItem => {
          let logo = null;
          if (resultItem?.data?.logo) {
            logo = resultItem?.data?.logo;
          } else if (resultItem?.data?.icon) {
            logo = resultItem?.data?.icon;
          } else if (resultItem?.data?.image) {
            logo = resultItem?.data?.image;
          }

          return {
            type: resultItem?.type ?? null,
            id: resultItem?.data?.id ?? null,
            logo,
            title: resultItem?.data?.title ?? null,
            developer_name: resultItem?.data?.developer_name ?? null,
          }
        });

        this.appSearchLoaded = true;
      }
    },
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
    async onTrackingChanged() {
      this.$store.dispatch('UPDATE_USER_LIMITS');
      this.$store.dispatch('keywordsTracking/SET_TRACKING_KEYWORDS_COUNT');
      await this.fetchDataCounts();
    },
    setHeadersToDefault() {
      const headers = this.headers.map(item => {
        return {
          ...item,
          isActive: true,
        }
      });

      this.headers = [...headers];
    },
  },
  unmounted() {
    removeEventListener("wheel", this.onWheel);
  },
  async mounted() {
    addEventListener("wheel", this.onWheel);

    if (this.$route.query.app_id) {
      this.selectedApp = {
        id: this.$route.query.app_id,
        title: this.$route.query.title,
        store: this.currentApp.store,
      };
    } else {
      this.selectedApp = {...this.currentApp};
    }

    this.searchResultsLoading = false;
    this.headers = this.currentApp?.store?.key === 'APP_STORE' ? [...rankingKeywordsTableConfig?.headers] : [...rankingKeywordsGPTableConfig?.headers];
    this.setHeadersToDefault();
    this.currentCountryCode = this.currentCountry?.code;
    const initialQueryString = window.location.search;

    if (initialQueryString !== '') {
      this.setInitialQueryUrl(initialQueryString);
      const {date_from, date_to} = decomposeUrlToObject(this.initialUrlQueryParams, ['date_from', 'date_to']);
      const reversedDateFrom = reverseDayMonthDate(date_from);
      const reversedDateTo = reverseDayMonthDate(date_to);

      let tempFilterObj = {
        start: reversedDateFrom,
        end: reversedDateTo,
      }

      this.minDate = getDefaultMinDate();
      this.maxDate.setDate(new Date().getDate() - 1);
      this.dateFilter = {...tempFilterObj};
      this.datePickerRerenderKey++;

      await this.fetchDataCounts();
    } else {
      this.minDate = getDefaultMinDate();
      this.maxDate.setDate(new Date().getDate() - 1);
      this.dateFilter = {...this.getLastDaysRange(1)};
    }

    await this.fetchDataCounts();

    this.searchResultsLoading = true;
    this.defaultDateFilters = false;
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'currentCountry',
      'currentCountryManual',
      'promiseSettledData',
      'dataCounts',
      'dataCountsLoaded',
      'userSubscription',
    ]),
    isFreeUser() {
      return this.userSubscription?.plan_name === 'Free';
    },
    workingApp() {
      if (this.selectedApp?.id) {
        return {...this.selectedApp, ...{store: this.currentApp.store}};
      } else if (this.currentApp?.id) {
        return this.currentApp;
      } else {
        return null;
      }
    },
    windowWidth() {
      return window.innerWidth;
    },
  },
  watch: {
    currentApp(newVal, oldVal) {
      if (newVal.id !== oldVal.id) {
        this.selectedApp = {...newVal};
      }
    },
    async selectedApp(newVal, oldVal) {
      //TODO костыляка. Флоу selectedApp нужно переделать, он через жопу и очень извилистую работает сейчас.
      if (newVal.id && (newVal.id !== oldVal.id || newVal.info === undefined)) {
        let appData = await myApps.getApp(this.selectedApp.id, this.currentCountry.code);
        this.selectedApp.info = appData.app_info;
      }
    },
  }
}

</script>

<style lang="scss" src="./styles.scss"></style>
<style scoped lang="scss">
.title_selector_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.ranking-keywords .search-keywords-block {
  display: flex;
  flex-direction: column;
  align-items: initial;
}
.selected_title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--neutral-900);

  a {
    cursor: pointer;
  }

  svg {
    height: 18px;
    margin-right: 5px;
  }
}
.selected_app_block {
  display: flex;
  align-items: center;

  .logo {
    margin-right: 10px;
  }

  .store_logo {
    margin-left: 5px;
    display: flex;
    align-items: center;
  }
}
.app_select_block {
  display: flex;
  align-items: center;

  .select_competitors {
    cursor: pointer;
    margin-left: 10px;
    color: var(--primary-default);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
}
</style>